<template>
  <div
    v-if="isVisible"
    class="ai-assist-container"
    :class="{ minimized: isMinimized }"
    :style="{ top: posY + 'px', left: posX + 'px' }"
  >
    <div class="ai-assist-header" @click="handleHeaderClick" @mousedown="startDrag">
      <h4 class="ai-assist-title">AI Author Assistant</h4>
      <div class="header-buttons">
        <button class="minimize-button" @click.stop="toggleMinimize">
          <img
            :key="isMinimized"
            :src="
              isMinimized
                ? require('../../../../assets/docEditor/maximize.png')
                : require('../../../../assets/docEditor/minimize.png')
            "
            :alt="isMinimized ? 'Maximize icon' : 'Minimize icon'"
            class="minimize-icon"
          />
        </button>
        <button class="close-button" @click.stop="$emit('close')">
          <img
            src="../../../../assets/docEditor/close.png"
            alt="Close icon"
            class="close-icon"
          />
        </button>
      </div>
    </div>

    <div class="file-path" v-if="shortFilePath">
      {{ shortFilePath }}
    </div>

    <simplebar
      class="custom-simplebar chat-container"
      v-show="!isMinimized"
      :class="{
        'd-flex align-items-center justify-content-center': !aiAuthorResponse,
      }"
    >
      <div
        class="chat-messages"
        :class="{
          'd-flex align-items-center justify-content-center': !aiAuthorResponse,
        }"
      >
        <div v-if="isAIResponseLoading" class="loader-container">
          <div class="loader"></div>
          <p>Fetching AI response...</p>
        </div>

        <div v-else-if="aiAuthorResponse" class="message assistant">
          <div class="highlighted-code" v-html="formattedHtmlContent"></div>
        </div>

        <div v-else class="error-message font-weight-bold text-align-center">
          ⚠️ No AI response available. Please try again later.
        </div>
      </div>

      <!-- <div class="chat-input" v-if="extractedXml && !isAIResponseLoading">
                <div class="row">
                    <div class="input-group">
                        <button class="send-button btn btn-md btn-primary mx-auto my-2" @click="handleReplace">
                            Replace
                        </button>
                    </div>
                </div>
            </div> -->
    </simplebar>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import marked from "marked";
import { eventBus } from "../../../../main";
import { saveFileContent } from "../../../../services/docEditorAPIs";

export default {
  components: { simplebar },
  props: {
    aiAuthorResponse: {
      type: String,
      required: true,
    },
    currentfilePath: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    isAIResponseLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isVisible: true,
      isMinimized: false,
      extractedXml: "",
      isDragging: false,
      posX: "unset",
      posY: "unset",
      offsetX: 0,
      offsetY: 0,
      isClickAllowed: true,
    };
  },
  computed: {
    formattedHtmlContent() {
      return marked(this.aiAuthorResponse);
    },
    shortFilePath() {
      if (!this.currentfilePath || !this.projectName) return "";

      const index = this.currentfilePath.indexOf(this.projectName);
      return index === -1
        ? this.currentfilePath
        : this.currentfilePath.substring(index + this.projectName.length + 1);
    },
  },
  watch: {
    aiAuthorResponse: {
      immediate: true,
      handler(newResponse) {
        this.extractXml(newResponse);
      },
    },
  },
  methods: {
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
      if (this.isMinimized) {
        this.posX = "unset";
        this.posY = "unset";
        this.$el.style.bottom = "1rem";
        this.$el.style.top = "unset";
      }
    },
    handleHeaderClick() {
      if (this.isClickAllowed && this.isMinimized) {
        this.isMinimized = false;
      }
    },
    extractXml(mdText) {
      const xmlRegex = /```xml\s*([\s\S]*?)\s*```/;
      const match = mdText.match(xmlRegex);
      this.extractedXml = match ? match[1].trim() : "";
    },
    async handleReplace() {
      if (!this.extractedXml) return;
      try {
        const path = this.currentfilePath;
        const content = this.extractedXml;

        if (!path || !content) return;

        const response = await saveFileContent({ path, content });
        if (response.status === 200) {
          console.log("File saved successfully:", response);
          eventBus.$emit("getcontent", { content, path });
          this.messageToast(
            "Success",
            "primary",
            "Content replaced successfully."
          );
        }
      } catch (error) {
        console.error("Error saving file:", error.response);
        this.messageToast(
          "Oops!",
          "danger",
          "Something went wrong! Please try again."
        );
      }
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    startDrag(event) {
      this.isDragging = true;
      this.isClickAllowed = false;
      this.offsetX = event.clientX - this.$el.getBoundingClientRect().left;
      this.offsetY = event.clientY - this.$el.getBoundingClientRect().top;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (!this.isDragging) return;
      this.posX = event.clientX - this.offsetX;
      this.posY = event.clientY - this.offsetY;

      this.$el.style.bottom = "unset";
      this.$el.style.right = "unset";
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);

      setTimeout(() => {
        this.isClickAllowed = true;
      }, 100);
    },
  },
};
</script>

<style>
.custom-simplebar {
  background-color: #ffff;
  height: 80vh;
  margin: 0;
  padding: 0;
}

.file-path {
  background-color: #f4f4f4;
  padding: 0.4rem;
  font-size: 0.8rem;
  color: #333;
  word-wrap: break-word;
  line-height: 1.1;
  padding-left: 1rem;
  font-weight: 500;
}

.ai-assist-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 400px;
  z-index: 1000;
  background-color: whitesmoke;
  padding-left: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.ai-assist-container:active {
  cursor: grabbing;
}

.ai-assist-container.minimized {
  height: auto;
  overflow: hidden;
  padding: 0.5rem;
}

.ai-assist-container.minimized .file-path {
  margin-bottom: 0;
  padding-left: 0;
}

.ai-assist-title {
  text-align: center;
  color: #333;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0;
}

.ai-assist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background: rgba(113, 165, 203, 0.2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
  cursor: grab;
}

.header-buttons {
  display: flex;
  gap: 4px;
}

.header-buttons:has(.minimize-button) {
  gap: 0;
}

.minimize-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.minimize-icon {
  height: 16px;
  width: 16px;
}

.close-icon {
  transition: transform 0.3s ease;
  height: 20px;
  width: 20px;
}

.minimize-icon:is(:hover, :focus) {
  cursor: pointer;
  transform: scale(1.2);
}

.close-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.close-icon:is(:hover, :focus) {
  transform: scale(1.1) rotate(180deg);
}

.chat-container {
  flex: 1;
  border-radius: 10px;
  overflow-y: auto;
}

.chat-messages {
  overflow-y: auto;
  padding: 1rem;
}

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
  background: #e9ecef;
  color: #333;
}

.highlighted-code pre {
  background: #282c34;
  color: #abb2bf;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
}

.highlighted-code p {
  margin: 0.5rem 0;
}

.highlighted-code h1 {
  font-weight: 800;
  font-size: 1.65rem;
}

.highlighted-code h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

.highlighted-code h3 {
  font-weight: 600;
  font-size: 1.2rem;
}

.highlighted-code h4 {
  font-weight: 500;
  font-size: 1.05rem;
  margin-bottom: 0;
}

.highlighted-code p {
  font-size: 0.8rem;
}

.send-button {
  padding: 0.25rem 0.75rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #282c34;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
}

.simplebar-scrollbar {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
