var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',{staticClass:"ai-assist-container",class:{ minimized: _vm.isMinimized },style:({ top: _vm.posY + 'px', left: _vm.posX + 'px' })},[_c('div',{staticClass:"ai-assist-header",on:{"click":_vm.handleHeaderClick,"mousedown":_vm.startDrag}},[_c('h4',{staticClass:"ai-assist-title"},[_vm._v("AI Author Assistant")]),_c('div',{staticClass:"header-buttons"},[_c('button',{staticClass:"minimize-button",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMinimize.apply(null, arguments)}}},[_c('img',{key:_vm.isMinimized,staticClass:"minimize-icon",attrs:{"src":_vm.isMinimized
              ? require('../../../../assets/docEditor/maximize.png')
              : require('../../../../assets/docEditor/minimize.png'),"alt":_vm.isMinimized ? 'Maximize icon' : 'Minimize icon'}})]),_c('button',{staticClass:"close-button",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('img',{staticClass:"close-icon",attrs:{"src":require("../../../../assets/docEditor/close.png"),"alt":"Close icon"}})])])]),(_vm.shortFilePath)?_c('div',{staticClass:"file-path"},[_vm._v(" "+_vm._s(_vm.shortFilePath)+" ")]):_vm._e(),_c('simplebar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMinimized),expression:"!isMinimized"}],staticClass:"custom-simplebar chat-container",class:{
      'd-flex align-items-center justify-content-center': !_vm.aiAuthorResponse,
    }},[_c('div',{staticClass:"chat-messages",class:{
        'd-flex align-items-center justify-content-center': !_vm.aiAuthorResponse,
      }},[(_vm.isAIResponseLoading)?_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader"}),_c('p',[_vm._v("Fetching AI response...")])]):(_vm.aiAuthorResponse)?_c('div',{staticClass:"message assistant"},[_c('div',{staticClass:"highlighted-code",domProps:{"innerHTML":_vm._s(_vm.formattedHtmlContent)}})]):_c('div',{staticClass:"error-message font-weight-bold text-align-center"},[_vm._v(" ⚠️ No AI response available. Please try again later. ")])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }